import MDinput from '@/components/MDinput';
import { validatorRequire, validatorRequireNumber } from '@/utils/validators';
import Select from '@/components/DSE/Select';
import Switch from '@/components/DSE/Switch';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 32,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    code: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'vehicle.code',
        maxlength: 8,
        required: true
      }
    },
    vehicleTypeID: {
      type: Select,
      defaultValue: null,
      props: {
        caption: 'common.vehicleType',
        required: true,
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['vehicleType/isLoading'],
        options: vue.$store.getters['vehicleType/loadedItems']
      },
      rules: [{ validator: validatorRequireNumber }]
    },
    onlineYN: {
      type: Switch,
      defaultValue: false,
      props: {
        activeText: 'common.yes',
        inactiveText: 'common.no'
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
